.labelFormLogin {
    width: 250px;
}

.inputLogin{
    margin-bottom: 10px;
}

.loginfaq{
    margin: 30px;
}

.info {
    margin-top: 10px;
    margin-bottom: 50px;
}
