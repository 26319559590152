.info {
    margin-top: 10px;
    margin-bottom: 20px;
}

.name {
    font-weight: 700;
}

.text {
    padding: 10px;
    overflow: hidden;
    word-wrap: break-word;
}

.list-item {
    display: flex;
    background-color: lightgray;
    width: 500px;
}

.list-item img {
    margin-right: 10px;
    width: 150px;
    height: 250px;
}