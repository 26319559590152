.horizontalVerA {
    display: flex;
    align-items: center;
}

.modelvera {
    position: relative;
    min-width: 769px;
    height: 500px;
    top: 20px;
}

.veramodel {
    background-color: lightgray;
    border-radius: 20px;
    height: 600px;
    width:800px;
    margin-bottom: 200px;
    margin-right: 50px;
}

.vertikalVerA {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.horizontalVerAModel {
    display: flex;
    align-items: center;

}


.threeWayValveVera.false {
    opacity: 0.5;
    pointer-events: none;
}

.pumpVerA.false {
    opacity: 0.5;
    pointer-events: none;
}

.veracontainer {
    justify-content: center;
}

.threeWayValveVera {
    position: relative;
}

.vera1 {
    position: relative;
    left: 51px;
    top: -12px;
}

.verav6container{
    position: relative;
    top: -7px;
}



.v2vera {
    position: relative;
    top: -45px;
}

.arrowvera.on{
    background-color: green;
}

.verarotate{
    top: -7px;
        position: relative;
        rotate: 180deg;
}

.verawaste{
    position: relative;
    top: -25px;
}

.verav1tov2 {
    position: relative;
    left: -1px;
}

.verav1tov4 {
    position: relative;
    left: 5px;
}

.tankvera {
    position: relative;
    top: -28px;
    left: 50%;
    /* left: 30px; */
}

.p1vera {
    position: relative;
    left: 30%;
    /* left: 18px; */
    top: 10px;
}


.pumpcontainervera {
    position: relative;
    top: -95px;
    left: -48%;
}

.veratop2 {
    position: relative;
    top: -8px;
    left: 0%;
}

.p2vera {
    position: relative;
    top: -15px;
}

.v1vera {
    position: relative;
    left: 2px;
    top: 20px;
}

.stirrerrightvera {
    position: relative;
    left: 75px;
    top: -3px;
}

.verav1container {
    position: relative;
    top: -85px;
}

.valvesleftvera {
    position: relative;
    top: -20px;
    left: -5px;
}

.flowtubevera {
    position: relative;
    top: -35px;
}

.s3vera {
    position: relative;
    top: 2px;
    left: -22px;
}

.arrows2tos3 {
    position: relative;
    top: -35px;
    left: -5px;
}

.verav4tos4 {
    position: relative;
    top: -70px;
    left: 3px;
}

.verav4tos1 {
    position: relative;
    top: -105px;
    left: 4px;
}

.v4vera {
    position: relative;
    top: 40px;
}

.arrows1tos2 {
    position: relative;
    top: -70px;
    left: -5px;
}

.veras4tov5 {
    position: relative;
    top: -35px;
    left: -30px;
}

.s4vera {
    position: relative;
    top: -35px;
    left: -10px;
}

.v5vera {
    position: relative;
    top: -117px;
}

.s1vera {
    position: relative;
    top: -70px;
    left: -22px;
}

.s2vera {
    position: relative;
    top: -35px;
    left: -22px;
}

.threeWayValveVeraText {
    position: relative;
    left: 5px;
}

.stirrerVeraText {
    position: relative;
    left: 4px;
}

.pumpVerAText{
    position: relative;
    left: 30%;
}

@media screen and (max-width: 1350px) {

    .horizontalVerA {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    .horizontalVerAModel {
        display: flex;
        align-items: center;
    }

        .veramodel {
            background-color: lightgray;
            border-radius: 20px;
            height: 600px;
            width: 700px;
            margin-bottom: 200px;
            margin-left: 15%;
            margin-right: 50px;
        }
}