.labelFormAccountManagement {
    width: 250px;
}

.inputAccountManagement {
    margin-bottom: 10px;
}

.info{
    margin-top: 10px;
    margin-bottom: 50px;
}
