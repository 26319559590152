table {
    border-collapse: collapse;
}

input[type="checkbox"] {
    margin-right: 10px;
}

.info {
    margin-top: 10px;
    margin-bottom: 20px;
}

.admin-list{
    margin: 50px;
}

.admin-list-item{
    margin-bottom: 1rem;
}

.institution-table{
    margin-top: 10%;
}

.add-extern-institution-form{
    margin-bottom: 2%;
}

.configurate-institution{
    margin-top: 5%;
}


.institutionKeys{
    text-align: left;
    vertical-align: top;
    width: 300px;
    max-width: 300px;
    overflow-x: auto;
    white-space: nowrap;
}

.add-extern-institution{
    margin-top: 5%;
}

.admin-list-link{
  display: block;
  color: #333;
  font-weight: 800;
}

.labelFormAdminFunc {
    width: 250px;
}

.inputAdminFunc {
    margin-bottom: 10px;
}

.admintableuser{
        border: 1px solid black;
}

.admintablerequests {
    border: 1px solid black;
    margin: 30px;
}

.labelFormAddFacility {
    width: 300px;
}

.inputAddFacility {
    margin-bottom: 10px;
}

.settabledataadmin {
    padding: 8px;
    text-align: left;
    vertical-align: top;
    width: 200px;
    max-width: 380px;
    overflow-x: auto;
    white-space: nowrap;
    background-color: lightgray;
}


.settradmin {
    display: flex;
    overflow: hidden;
}


tr:hover {
    overflow: auto;
    height: auto;
}