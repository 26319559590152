*,
*::after,
*::before {
    margin: 0;
    box-sizing: border-box;
}

html{font-size: 62.5%;}

body{
    font-size: 1.6rem;
}

li{
    list-style: none;
}

.nav{
    position: fixed;
    top: 0;
    width: 100%;
    background-color:#00519E;
    color: white;
    display: flex;
    justify-content: space-between;
    
    align-items: center;
    height: 7vh;
    z-index: 999;
}


.site-title{
    margin: 40px;
    font-weight: bold;
}

.nav-menu{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
}


.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 40px;
}

.nav-toggler div {
    width: 2.5rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: white;
    transition: 0.3s ease-in;
}

.nav a{
    text-decoration: none;
    color: white;
}

.nav li:hover{
    background-color:steelblue;
}

.nav-toggler{
    cursor: pointer;
    display: none;
    margin: 30px;
}

@media screen and (max-width: 768px) {
    .nav-toggler{
        display: block;
        cursor: pointer;
    }
    .nav-menu{
        position: fixed;
        top: 7vh;
        right: 0;
        width: 50vw;
        height: 93vh;
        background: #00519E;
        flex-direction: column;
        transform: translateX(100%);
        transition: .5s ease-in;
        z-index: 999
    }

    .nav ul{
        margin: 0;
    }
    
}

.nav ul.nav-active {    
    transform: translateX(0);
}

.toggle .line1{
    transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
    opacity: 0;
}
.toggle .line3 {
        transform: rotate(45deg) translate(-4px, -5px);
}

