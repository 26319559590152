.modelSimpleAnITA {
    background-color: lightsalmon;
    border-radius: 20px;
    height: 550px;
    width: 1000px;
    min-width: 800px;
    margin-bottom: 2%;
    margin-left: 1%;
    margin-right: 1%;
}

.component-container-anita {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-width: 700px;
    min-height: 50px;
}

.horizontalButtonsAnITA{
    display: flex;
    margin-top: 2%;
}

.singleButtonAnITA{
    margin: 1%;
}

.horizontalAnITA {
    display: flex;
    align-items: center;
}


.pumpAnITA.false {
    pointer-events: none;
    opacity: 0.2;
}

.valveAnITA3.false {
    pointer-events: none;
    opacity: 0.2;
}

.valveAnITA.false {
    pointer-events: none;
    opacity: 0.2;
}


.pumpAnITA {
    position: relative;
    top: 30px;
}

.textinput {
    width: 6vw;
}

.left {
    transform: scaleX(-1);
}

.valveAnITA {
    position: relative;
    top: 13px;
}

.valveAnITAText {
    position: relative;
    left: 8px;
    top: 5px;
}


.valve3AnITAText {
    position: relative;
    left: +25px;
    top: -25px;
}

.pumpAnITAText {
    position: relative;
    left: 2px;
    top: 30px;
}

.arrowAnITA1 {
    color: green;
    background-color: green;
}

.arrowAnITA2 {
    position: relative;
    top: 5px
}

.arrowAnITA2.on {
    background-color: green;
}

.arrowAnITA3 {
    position: relative;
    top: 46px;
}

.arrowAnITA3.on {
    background-color: green;
}

.arrowAnITA4 {
    position: relative;
    left: 2px;
    background-color: green;
}

.arrowAnITA5 {
    position: relative;
    left: 110px;
    top: -20px;
    margin: 5px;

}

.arrowAnITA5.on {
    background-color: green;

}

.valveAnITA3 {
    margin: 2px;
    position: relative;
    left: -1px;
    rotate: 90deg;
}

.autocalveAnITAText {
    position: relative;
    left: 40px;
}

.wasteAnITAText {
    position: relative;
    left: 340px;
    top: -40px;
}



@media screen and (max-width: 1350px) {
    .horizontalAnITA {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}