.component-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-width: 860px;
    min-height: 50px;
}

.experiment-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
}

.experiment-form label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.experiment-form input[type="checkbox"] {
    margin-right: 10px;
}

.experiment-description {
    margin-top: 2%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    width: 60%;
}

.experimentAnITAInfos {
    margin-right: 50px;
}

.disabled{
    opacity: 0.2;
    pointer-events: none;
}