html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
}

li {
    list-style: none;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #00519E;
    color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;
    height: 7vh;
    z-index: 998;
}

.footer ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 40px;
}


.footer li:hover {
    background-color: steelblue;
}

.footer a {
    text-decoration: none;
    color: white;
}

