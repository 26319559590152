.labelFormRegister{
    width: 250px;
}

.inputRegister{
    margin-bottom: 10px;
}

.registerdsgvo{
    max-width: 300px;
    margin: 40px;
}

.registerfaq{
    margin: 40px;
}

.info {
    margin-top: 10px;
    margin-bottom: 50px;
}


.alignItems{
    align-items: center;
}