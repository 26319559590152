.calendar{
    overflow: auto;
    height: 500px;
}

.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
.modal-content {
    background-color: #f8f9fa;
    border-radius: 10px;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.selectable {
    cursor: pointer;
}

