.imageVerA{
    margin: 30px;
    border-radius: 10px;
}

.imageAnITA{
    margin: 30px;
    border-radius: 10px;
}

.textAnITA{
    max-width: 300px;
}
.textVerA{
    max-width: 300px;
}

