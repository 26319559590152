*{
    word-wrap: break-word;
}

.global{
    margin: 20px;
}

.flex-container{
    display: flex;
}

.horizontal {
    display: flex;
    align-items: center;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    margin-bottom: 200px;
}

.vertikal {
    display: flex;
    flex-direction: column;
}

.selection-menu {
    margin: 15px;
    width: 200px;
    height: 30px;
}

@media (max-width: 768px) {
    .horizontal {
        flex-direction: column;
    }
}